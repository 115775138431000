import React, { Component } from "react";
import { notification } from "antd";
import "../ViewerPage.css";
import "antd/dist/antd.css";
import { ExamImagesApiService } from "../../../api/ExamImagesApi.service";
import { ExamApiService } from "../../../api/ExamApi.service";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import ViewerBase from ".././components/ViewerBase/ViewerBase";
import { ReportService } from "../../../services/reportService";

class SGViewerPage extends Component {
  constructor(props) {
    super(props);
    this.onExamChanged = this.onExamChanged.bind(this);
  }

  saveExams = (exam) => {
    ExamApiService.setExaminationStatus(exam.examinationId, {
      left: exam.leftEyeDiagnosis,
      right: exam.rightEyeDiagnosis,
      doctorNote: exam.doctorNote,
    }).then(
      () => {
        this.props.history.push(`/dashboard/examsList`);
      },
      () => {
        notification.open({
          message: "Error",
          description: "Failed to save diagnosis",
        });
      }
    );
  };

  loadExamData(
    examId,
    setExamHistory,
    onImageLoaded,
    onImageLoadingFailed,
    setImages
  ) {
    return Promise.all([
      ExamImagesApiService.getImages(examId),
      ExamApiService.getExaminationsAnalysisResult(examId),
      ExamApiService.getSSMUExamination(examId),
    ]).then(([images, [leftResults, rightResults], exam]) => {
      this.setState({
        ...this.state,
        images,
        leftResults,
        rightResults,
        exam,
      });

      ExamApiService.getSSMUExaminations(exam.patientNumber).then((data) => {
        data.sort((a, b) => {
          if (moment(a.examinationDate) > moment(b.examinationDate)) {
            return -1;
          }
          if (moment(a.examinationDate) < moment(b.examinationDate)) {
            return 1;
          }

          return 0;
        });

        setExamHistory(data);
        this.setState({
          ...this.state,
          examinationSSMU: data,
        });
      });

      images.forEach((image) => {
        image.domImage = new window.Image();

        ExamImagesApiService.getImage(image.id)
          .then((imageBlob) => {
            image.domImage.src = URL.createObjectURL(imageBlob);
            image.imageBlob = imageBlob;
          })
          .catch(() => {
            if (onImageLoadingFailed) onImageLoadingFailed(image);
          });

        image.domImage.onload = () => {
          onImageLoaded();
        };

        image.domImage.onerror = () => {
          if (onImageLoadingFailed) onImageLoadingFailed(image);
        };

        let examResults = {
          left: leftResults,
          right: rightResults,
        };

        let fileResult = examResults[image.eyeSide][image.fileName];

        if (fileResult) {
          image.analysis = fileResult;

          image.hasRetinopathy = !!fileResult.desease_prediction;
          image.hasAmd = fileResult.has_signs_of_amd;
          image.hasGlaucoma = fileResult.has_signs_of_glaucoma;
          image.gradable = fileResult.gradable;
        }
      });

      setImages(images, exam);
    });
  }

  onExamChanged(value, selectedImageEyeSide) {
    this.props.history.push(
      `/dashboard/sgviewer/${value}/${selectedImageEyeSide}`
    );
  }

  async onReportCreate() {
    const fetchingImagesVesselMasks = this.state.images.map((image) =>
      ExamImagesApiService.getVesselsMasks(image.analysis.analysis_result_id)
    );
    const vesselMasks = await Promise.all(fetchingImagesVesselMasks).catch(() => []);
    return new ReportService(
      this.state.exam,
      this.state.images,
      vesselMasks
    ).generate();
  }

  render() {
    return (
      <ViewerBase
        onSaveExam={this.saveExams}
        onExamChanged={this.onExamChanged}
        onLoadExamData={(...args) => this.loadExamData(...args)}
        onReportCreate={() => this.onReportCreate()}
        showReportButton={
          this.state &&
          this.state.exam &&
          this.state.images &&
          this.state.exam.examinationStatus === "PROCESSED"
        }
      ></ViewerBase>
    );
  }
}

export default connect()(compose(withRouter)(SGViewerPage));
