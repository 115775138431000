import React, { Component } from "react";
import { Layout, Icon } from "antd";
import ClientNotifications from "../../components/ClientNotifications/ClientNotifications";
import ImageViewer from "../../components/ImageViewer/ImageViewer";
import { MLButtonsSubpanel } from "../ControlPanel/MLButtonsSubpanel/MLButtonsSubpanel";
import { ColorModeButtonsSubpanel } from "../ControlPanel/ColorModeButtonsSubpanel/ColorModeButtonsSubpanel";
import "./ContentPanel.css";
import { ExamImagesApiService } from "../../../../api/ExamImagesApi.service";

export class ContentPanel extends Component {
	state = {
		image: undefined,
		isVesselsMaskShown: false,
		isROIShown: false,
		isHEShown: false,
		isMAShown: false,
		isMXShown: false,
		colorMode: undefined,
		isToolboxShown: false,
		vesselsMask: undefined
	}

	componentDidMount() {
		if (this.state.image !== this.props.image) {
			console.log(this.props);
			this.setState({
				...this.state,
				image: this.props.image,
				isROIShown: this.props.image && this.props.image.hasRetinopathy
			});

			if (this.props.image && this.props.image.analysis.analysis_result_id) {
				this.loadVesselsMask();
			}
		}
	}

	componentDidUpdate() {
		if (this.state.image !== this.props.image) {
			console.log(this.props);
			this.setState({
				...this.state,
				image: this.props.image,
				isROIShown: this.props.image && this.props.image.hasRetinopathy
			});

			if (this.props.image && this.props.image.analysis.analysis_result_id) {
				this.loadVesselsMask();
			}
		}
	}

	toggleToolbox() {
		this.setState((prevState) => ({ ...this.state, isToolboxShown: !prevState.isToolboxShown }));
	}

	loadVesselsMask() {
		ExamImagesApiService.getVesselsMasks(this.props.image.analysis.analysis_result_id)
			.then((maskData) => {
				this.setState({ ...this.state, vesselsMask: maskData });
			})
	}

	showMask = (maskName, value) => {
		this.setState({
			...this.state,
			[maskName]: value
		});
	}

	setColorMode = (colorMode) => {
		this.setState({
			...this.state,
			colorMode
		})
	}

	render() {
		const { image } = this.state;

		if (!image || !image.domImage.complete || !image.domImage.src)
			return <ClientNotifications notification="Choose images in menu" />;

		return (
			<Layout>
				<Layout className={"vievier-content"}>
					<ImageViewer image={image} {...this.state} analysis={image.analysis} vessels_mask={this.state.vesselsMask} />
					<div className={"toolbox " + (!this.state.isToolboxShown ? 'hidden' : '')}>
						<button className="toolbox-toggle-button" onClick={() => this.toggleToolbox()}>
							{this.state.isToolboxShown ? <Icon type="down" /> : <Icon type="up" />}

						</button>
						<MLButtonsSubpanel vessels_mask={this.state.vesselsMask} analysis={image.analysis} {...this.state} showMask={this.showMask} />
						<ColorModeButtonsSubpanel colorMode={this.state.colorMode} setColorMode={this.setColorMode} />
					</div>
				</Layout>
			</Layout>
		);
	}
}
