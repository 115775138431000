export async function readBlobAsDataUrl(blob) {
  const reader = new FileReader();
  return new Promise((res, rej) => {
    reader.addEventListener("load", () => {
      res(reader.result);
    });
    reader.addEventListener("error", () => {
      rej(reader.error);
    });
    reader.readAsDataURL(blob);
  });
}
