import React, { useEffect, useState } from "react";
import { Icon } from "antd";
import { readBlobAsDataUrl } from "../../utils/ImageUtils";
import "./FileImagePreview.scss";

export function FileImagePreview(props) {
  const [fileImageUrl, setFileImageUrl] = useState(null);

  useEffect(() => {
    setFileImageUrl(null);
    if (!props.file) {
      return;
    }
    readBlobAsDataUrl(props.file).then((fileImgUrl) => {
      setFileImageUrl(fileImgUrl);
      if (props.onPreviewLoad) {
        props.onPreviewLoad(fileImgUrl);
      }
    });
  }, [props.file, setFileImageUrl]);

  return (
    <div className={[props.className || "", `file-image-preview`].join(" ")}>
      {fileImageUrl ? (
        <div className="file-image-preview__content file-image-preview-content">
          <img
            className="file-image-preview-content__image"
            src={fileImageUrl}
            alt="avatar"
          />
          {props.children && (
            <div className="file-image-preview-content__overlay file-image-preview-content-overlay">
              {props.children}
            </div>
          )}
        </div>
      ) : (
        <div className="file-image-preview__loading-icon file-image-preview-loading-icon">
          <Icon
            className="file-image-preview-loading-icon__icon"
            type="loading"
          />
        </div>
      )}
    </div>
  );
}
