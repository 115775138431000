import React, { Component } from "react";
import { Layout } from "antd";
import { Menu } from "../Menu/Menu";
import { PatientName } from "../PatientName/PatientName";
import  {ContentPanel} from "../ContentPanel/ContentPanel"
import { Preloader } from "../../../../components/Preloader/Preloader";
import "./ViewerBase.scss";
import "antd/dist/antd.css";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import ExamDate from "../ExamDate/ExamDate";
import  {connect} from "react-redux";

class ViewerBase extends Component {
	constructor(props) {
		super(props);
        this.setExamHistory = this.setExamHistory.bind(this);
        this.stopLoading = this.stopLoading.bind(this);
        this.onImageLoadingFailed = this.onImageLoadingFailed.bind(this);
        this.setImages = this.setImages.bind(this);
	}

	state = {
		isLoading: true,
		images: [],
		image: undefined,
		exam: undefined,
		selectedImageEyeSide: undefined,
		examsHistory: []
	}

	selectImage = (image) => {
		this.setState({ ...this.state, image: image});
	}

	setExaminationStatus = (eyeSide, data) => {
		let typeKey = eyeSide === "left" ? "leftEyeDiagnosis" : "rightEyeDiagnosis";
		let exam = { ...this.state.exam };
		exam[typeKey] = data;

		this.setState({ ...this.state, exam: exam });
	}

	setDoctorNote = (note) => {
		let exam = { ...this.state.exam };
		exam.doctorNote = note;

		this.setState({ ...this.state, exam: exam });
	}

	componentDidMount() {
		let examId = +this.props.match.params.id;

        this.startLoading();
		this.props.onLoadExamData(examId, this.setExamHistory, this.stopLoading, this.onImageLoadingFailed,
            this.setImages);

		let selectedEyeSide = this.props.match.params.eyeSide;
		this.setState({ ...this.state, selectedImageEyeSide: selectedEyeSide});

		window.addEventListener("wheel", this.handleWheel, { passive: false });
	}

	componentDidUpdate() {
		let examId = +this.props.match.params.id;

		if (this.state.isLoading || examId === this.state.exam.examinationId)
			return; 

		let selectedEyeSide = this.props.match.params.eyeSide;
		this.setState({ ...this.state, selectedImageEyeSide: selectedEyeSide});

        this.startLoading();
		this.props.onLoadExamData(examId, this.setExamHistory, this.stopLoading, this.onImageLoadingFailed,
            this.setImages);
	}

    setExamHistory(data) {
        this.setState({ ...this.state, examsHistory: data});
    }

    stopLoading() {
        this.setState({isLoading: false});
    }

    startLoading() {
        this.setState({isLoading: true});
    }

    onImageLoadingFailed(image) {
        this.setState({images: this.state.images.map(x => {
            if (x.id === image.id) {
                x.loadFailed = true;
            }
            
            return x;
        }), isLoading: false});
    }

    setImages(images, exam) {
        this.setState({images: images, image: undefined, exam: exam });
    }

	componentWillUnmount() {
		window.removeEventListener("wheel", this.handleWheel);
	}

	handleWheel = event => {
		if (event.ctrlKey) {
			event.preventDefault();
		}
	};

	render() {
		return (
			<Layout className="preloader-container viever">
				{this.state.images.length > 0 && <Menu
					exam={this.state.exam}
					eyeImages={this.state.images}
					selectedImage={this.state.image}
					selectImage={this.selectImage}
					selectedImageEyeSide={this.state.selectedImageEyeSide}
					setExaminationStatus={this.setExaminationStatus}
					saveExams={() => this.props.onSaveExam(this.state.exam)}
					setDoctorNote={this.setDoctorNote}
					showReportButton={this.props.showReportButton}
					onReportCreate={this.props.onReportCreate}
				/>}
				<Layout className="viewer-container">
					{this.state.exam && <div className="viewer-top-container">
						<PatientName exam={this.state.exam}></PatientName>
						{this.state.image &&<ExamDate
                            onExamChanged={this.props.onExamChanged}
                            selectedImageEyeSide={this.state.image.eyeSide} 
							exam={this.state.exam} examsHistory={this.state.examsHistory}>
						</ExamDate>}
					</div>}
	
					<ContentPanel image={this.state.image} />
				</Layout>
				{
					this.state.isLoading && (
						<Preloader />)
				}
			</Layout>
		)
	}
}

export default connect()(compose(withRouter)(ViewerBase));
