import React, { useEffect, useState } from "react";
import { Image } from "react-konva";

export default function VesselsMaskImage(props) {
    const [loadedVesselsMaskImage, setLoadedVesselsMaskImage] = useState(null);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (!props || !props.vessels_mask || !props.vessels_mask.size)
            return;

        setScale(props.scaleCoef);

        const imageSrc = URL.createObjectURL(props.vessels_mask);
        if (!loadedVesselsMaskImage || loadedVesselsMaskImage.src !== imageSrc) {
            let newImage = new window.Image();

            newImage.src = imageSrc;
            newImage.onload = () => {
                newImage.width = props.sourceImage.width;
                newImage.height = props.sourceImage.height;
                setLoadedVesselsMaskImage(newImage);
            };
        } else {
            loadedVesselsMaskImage.width = props.sourceImage.width;
            loadedVesselsMaskImage.height = props.sourceImage.height;
        }
    }, [props.sourceImage, props.vessels_mask]);

    return (
        <Image
            scaleX={scale}
            scaleY={scale}
            image={loadedVesselsMaskImage}
            opacity={props.isVesselsMaskShown ? 1 : 0}
            globalCompositeOperation={"exclusion"} />
    )
}