import React from "react";
import { jsPDF } from "jspdf";
import { extractDateParts } from "../utils/DateTimeUtils";
import ReactDOM from "react-dom";
import ImageViewer from "../pages/viewer/components/ImageViewer/ImageViewer";

export class ReportService {
  #examData;
  #imagesData;
  #imageVesselsMasks;
  #pagePadding = 10;
  #lineSpacing = 7;
  #doc = new jsPDF();
  #defaultFontSize = 14;
  #titleFontSize = 16;
  #regularFontName = "Inter-Regular";
  #boldFontName = "Inter-Bold";

  constructor(examData, imagesData, vesselsData) {
    this.#examData = examData;
    this.#imagesData = imagesData;
    this.#imageVesselsMasks = vesselsData;
  }

  async _loadAndRegisterFont(fontName, fontWeight) {
    const fontFileName = `${fontName}.ttf`;
    if (!this.#doc.existsFileInVFS(fontFileName)) {
      await new Promise((res) => {
        this.#doc.loadFile(`/fonts/${fontFileName}`, false, (fontFile) => {
          this.#doc.addFileToVFS(fontFileName, fontFile);
          res();
        });
      });
    }
    this.#doc.addFont(fontFileName, fontName, fontWeight);
    return fontFileName;
  }

  async generate() {
    await this._loadAndRegisterFont(this.#regularFontName, "normal");
    await this._loadAndRegisterFont(this.#boldFontName, "normal");
    this.#doc.setFontSize(this.#defaultFontSize);

    this.#doc.setFont(this.#boldFontName);
    this.#doc.setFontSize(this.#titleFontSize);
    let nextParY = this._addWrappedParagraph(
      "Диагностический отчет",
      this.#pagePadding
    );

    const timeFormatter = new Intl.DateTimeFormat("ru-RU", {
      timeStyle: "short",
    });
    const examinationDate = new Date(this.#examData.examinationDate);
    const [date, time] = extractDateParts(examinationDate, timeFormatter);

    const diseaseNameMap = {
      hasAmd: "признаки AMD",
      hasBrvo: "BRVO",
      hasCrvo: "CRVO",
      hasGlaucoma: "признаки глаукомы",
      hasRetina: "диабетическая ретинопатия",
    };
    const diagnosisParts = [];
    Object.entries(diseaseNameMap).forEach(([key, value]) => {
      if (
        this.#examData.leftEyeDiagnosis[key] &&
        this.#examData.rightEyeDiagnosis[key]
      ) {
        diagnosisParts.push(value);
        return;
      }
      if (this.#examData.leftEyeDiagnosis[key]) {
        diagnosisParts.push(`${value} (левый глаз)`);
      }
      if (this.#examData.rightEyeDiagnosis[key]) {
        diagnosisParts.push(`${value} (правый глаз)`);
      }
    });

    const predictionPct = this.#imagesData
      .map(
        (i) =>
          `${i.analysis.desease_prediction * 100}% (${
            i.eyeSide === "right" ? "правый" : "левый"
          } глаз)`
      )
      .join(", ");

    const examDate = new Intl.DateTimeFormat("ru-RU", {
      dateStyle: "short",
    }).format(date);

    this.#doc.setFont(this.#regularFontName);
    this.#doc.setFontSize(this.#defaultFontSize);
    nextParY = this._addDescriptionListParagraphs(
      [
        { term: `УНК пациента`, details: [this.#examData.patientNumber] },
        { term: `Дата проведения анализа`, details: [examDate] },
        { term: `Время проведения анализа`, details: [time] },
        {
          term: `Изображение пригодно для оценки`,
          details: [
            this.#imagesData.every((i) => i.analysis.gradable) ? "да" : "нет",
          ],
        },
        {
          term: `Изображение применимо для оценки`,
          details: [
            this.#imagesData.every((i) => i.analysis.applicable) ? "да" : "нет",
          ],
        },
        {
          term: `Диагноз`,
          details: diagnosisParts.length ? diagnosisParts : ["-"],
        },
        {
          term: `Вероятностная оценка`,
          details: [predictionPct],
        },
      ],
      nextParY + this.#lineSpacing,
      this.#pagePadding
    );

    if (this.#examData.doctorNote) {
      nextParY = this._addDescriptionListParagraph(
        `Заметки`,
        [this.#examData.doctorNote],
        nextParY
      );
    }

    this.#doc.setFont(this.#boldFontName);

    this.#doc.addPage();
    this.#doc.setFontSize(this.#titleFontSize);
    nextParY =
      this._addWrappedParagraph(`Исходные изображения`, this.#pagePadding) +
      this.#lineSpacing;
    this.#doc.setFontSize(this.#defaultFontSize);
    for (const img of this.#imagesData) {
      nextParY = await this._addWrappedEyeImage(nextParY, img);
    }

    if (diagnosisParts.length) {
      this.#doc.addPage();
      this.#doc.setFontSize(this.#titleFontSize);
      nextParY =
        this._addWrappedParagraph(
          `Маски поражений глазного дна`,
          this.#pagePadding
        ) + this.#lineSpacing;
      this.#doc.setFontSize(this.#defaultFontSize);
      for (const img of this.#imagesData) {
        nextParY = await this._addWrappedEyeImage(nextParY, img, {
          isROIShown: true,
        });
      }
    }

    if (
      this.#imageVesselsMasks &&
      this.#imageVesselsMasks.some((ivm) => ivm.size)
    ) {
      this.#doc.addPage();
      this.#doc.setFontSize(this.#titleFontSize);
      nextParY =
        this._addWrappedParagraph(`Маски сосудов`, this.#pagePadding) +
        this.#lineSpacing;
      this.#doc.setFontSize(this.#defaultFontSize);
      for (let i = 0; i < this.#imagesData.length; ++i) {
        const vesselsMask = this.#imageVesselsMasks[i];
        if (!vesselsMask || !vesselsMask.size) {
          continue;
        }
        const img = this.#imagesData[i];
        nextParY = await this._addWrappedEyeImage(nextParY, img, {
          vessels_mask: vesselsMask,
          isVesselsMaskShown: true,
        });
      }
    }

    this.#doc.save(
      `Диагностический отчет ${this.#examData.patientNumber} ${examDate}.pdf`
    );
  }

  async _renderHiddenEyeImage({ image, renderOptions }) {
    const el = document.createElement("div");
    el.style.display = "flex";
    el.style.height = `${image.domImage.height}px`;
    el.style.width = `${image.domImage.width}px`;
    el.style.visibility = "hidden";

    document.body.appendChild(el);
    const node = await new Promise((res) => {
      ReactDOM.render(
        <ImageViewer
          ref={(viewerRefs) => {
            if (!viewerRefs.stage) {
              return;
            }
            const layer = viewerRefs.stage.getLayers()[0];
            if (!layer) {
              return;
            }
            const layerDrawCompletionDelayMs = 500;
            setTimeout(() => {
              res(layer);
            }, layerDrawCompletionDelayMs);
          }}
          image={image}
          analysis={image.analysis}
          {...renderOptions}
        />,
        el
      );
    });
    return {
      cleanupFn: () => {
        document.body.removeChild(el);
      },
      canvas: node.toCanvas(),
    };
  }

  async _addWrappedEyeImage(y, image, renderOptions) {
    const { canvas, cleanupFn } = await this._renderHiddenEyeImage({
      image,
      renderOptions,
    });

    y = this._addWrappedParagraph(
      `${image.eyeSide === "right" ? "Правый" : "Левый"} глаз:`,
      y
    );

    const imageWidth =
      this.#doc.internal.pageSize.width - this.#pagePadding * 2;
    const imageHeight =
      (image.domImage.height / image.domImage.width) * imageWidth;

    if (
      y + imageHeight - this.#lineSpacing / 2 >
      this.#doc.internal.pageSize.height
    ) {
      this.#doc.addPage();
      y = this.#pagePadding;
    }
    this.#doc.addImage(
      canvas,
      "JPEG",
      this.#pagePadding,
      y - this.#lineSpacing / 2,
      imageWidth,
      imageHeight
    );
    cleanupFn();
    return y + imageHeight + this.#lineSpacing / 2;
  }

  _addWrappedParagraph(
    text,
    initialYPosition,
    lineSpacing = this.#lineSpacing,
    xOffset = this.#pagePadding
  ) {
    const textWidth =
      this.#doc.internal.pageSize.width - xOffset - this.#pagePadding;
    const textLines = this.#doc.splitTextToSize(text, textWidth);
    const pageHeight = this.#doc.internal.pageSize.height;
    let cursorY = initialYPosition;

    textLines.forEach((lineText) => {
      if (cursorY > pageHeight) {
        this.#doc.addPage();
        cursorY = this.#pagePadding;
      }
      this.#doc.text(lineText, xOffset, cursorY);
      cursorY += lineSpacing;
    });

    return cursorY;
  }

  _addWrappedParagraphs(
    paragraphs,
    initialYPosition,
    lineSpacing = this.#lineSpacing,
    xOffset = this.#pagePadding
  ) {
    paragraphs.forEach((p) => {
      initialYPosition = this._addWrappedParagraph(
        p,
        initialYPosition,
        lineSpacing,
        xOffset
      );
    });
    return initialYPosition;
  }

  _addDescriptionListParagraph(
    descriptionTerm,
    descriptionDetailsList,
    initialYPosition,
    lineSpacing = this.#lineSpacing,
    xOffset = this.#pagePadding
  ) {
    this.#doc.setFont(this.#boldFontName);
    const termText = `${descriptionTerm}: `;
    this.#doc.text(termText, xOffset, initialYPosition);
    const termColumnWidth = this.#doc.getTextWidth(termText);
    this.#doc.setFont(this.#regularFontName);
    return this._addWrappedParagraphs(
      descriptionDetailsList,
      initialYPosition,
      lineSpacing,
      termColumnWidth + xOffset
    );
  }

  _addDescriptionListParagraphs(
    descriptionList,
    initialYPosition,
    lineSpacing = this.#lineSpacing,
    xOffset = this.#pagePadding
  ) {
    descriptionList.forEach((dl) => {
      initialYPosition = this._addDescriptionListParagraph(
        dl.term,
        dl.details,
        initialYPosition,
        lineSpacing,
        xOffset
      );
    });
    return initialYPosition;
  }
}
