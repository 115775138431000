import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Button, Checkbox, Input, Layout, message, Select, Icon } from "antd";
import "antd/dist/antd.css";
import { withRouter } from "react-router-dom";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import "./UploadPage.scss";
import { UserApiService } from "../../api/UserApi.service.";
import { ExamApiService } from "../../api/ExamApi.service";
import ImageViewer from "../viewer/components/ImageViewer/ImageViewer";
import { UserFeaturesService } from "../../services/userFeaturesEnum";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DicomViewer } from "../../components/DicomViewer/DicomViewer";
import { FileImagePreview } from "../../components/FileImagePreview/FileImagePreview";
import { DicomService, ExamImagesApiService } from "../../api";
import { ImageCheckStatus } from "../../components/ImageCheckStatus";

const { Option } = Select;

function AddExamPage(props) {
  const { t } = useTranslation();
  const features = useSelector((x) => x.account.features);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userDepartments, setUserDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState({});
  const [referenceId, setReferenceId] = useState("");
  const [department, setDepartmentId] = useState(null);
  const [isPatientDiabetic, setIsPatientDiabetic] = useState(false);
  const [isNoContradiction, setIsNoContradiction] = useState(false);
  const [forceToSendNotGradable, setForceToSendNotGradable] = useState(false);
  const [isEyeDropUsed, setIsEyeDropUsed] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  const [dicomViewerOpenedForKey, setDicomViewerOpenedForKey] = useState(null);
  const [isDicomFileBeingProcessed, setIsDicomFileBeingProcessed] = useState(
    false
  );

  const hasNoImages = useMemo(() => {
    const fileValues = Object.values(files);
    return !fileValues.length || fileValues.every((v) => v == null);
  }, [files]);

  const hasInvalidImage = useMemo(
    () =>
      Object.values(files).some(
        (v) => v != null && v.checkResult != null && !v.checkResult.applicable
      ),
    [files]
  );

  const hasNotGradableImage = useMemo(
    () =>
      Object.values(files).some(
        (v) => v != null && v.checkResult != null && !v.checkResult.gradable
      ),
    [files]
  );

  const setImageToViewer = useCallback(
    (imageUrl) => {
      var img = {};
      img.domImage = new Image();
      img.domImage.src = imageUrl;
      img.domImage.onload = () => {
        setSelectedImage(img);
      };
    },
    [setSelectedImage]
  );

  const onSelect = useCallback(
    (val) => {
      setFormSubmited(false);
      setDepartmentId(val);
    },
    [setFormSubmited, setDepartmentId]
  );

  const onFileUpload = useCallback(
    (file, checkResult, key) => {
      setFormSubmited(false);
      setDicomViewerOpenedForKey(null);

      setFiles({
        ...files,
        [key]: { file, checkResult, source: "local" },
      });
    },
    [files, setFiles, setFormSubmited, setDicomViewerOpenedForKey]
  );

  const onFileUploadError = useCallback(
    (file, key) => {
      setFormSubmited(false);
      setDicomViewerOpenedForKey(null);
      setSelectedImage(null);
    },
    [setFormSubmited, setDicomViewerOpenedForKey, setSelectedImage]
  );

  const onFileRemoved = useCallback(
    (key) => {
      setFormSubmited(false);
      setDicomViewerOpenedForKey(null);
      setSelectedImage(null);
      const newFiles = { ...files };
      delete newFiles[key];
      setFiles(newFiles);
    },
    [
      setFormSubmited,
      setDicomViewerOpenedForKey,
      setSelectedImage,
      files,
      setFiles,
    ]
  );

  useEffect(() => {
    UserApiService.getDepartments().then((x) => {
      setUserDepartments(x);
      setDepartmentId(x.length !== 0 ? x[0].id : null);
    });
  }, [features]);

  const onReferenceChange = useCallback(
    (e) => {
      setFormSubmited(false);
      setReferenceId(e.target.value);
    },
    [setFormSubmited, setReferenceId]
  );

  const onSubmit = useCallback(() => {
    setFormSubmited(true);

    if (hasNotGradableImage && !forceToSendNotGradable) {
      return;
    }

    setIsLoading(true);

    let leftEyeImages = [files["os-m"], files["os-d"]]
      .filter((x) => x != null)
      .map((x) => x.file);
    let rightEyeImages = [files["od-m"], files["od-d"]]
      .filter((x) => x != null)
      .map((x) => x.file);

    let exam = {
      patientNumber: referenceId,
      departmentId: department,
      isPatientDiabetic,
      isNoContradiction,
      isEyeDropUsed,
      examinationDate: new Date(),
    };

    ExamApiService.createExam(exam, leftEyeImages, rightEyeImages)
      .then(() => {
        message.success(t("upload.examCreated"));
        var redirect = UserFeaturesService.getExamsListRouteOrDefault(features);
        if (redirect) {
          props.history.push(redirect.route);
        }
      })
      .catch(() => {
        message.error(t("upload.examCreationFailed"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    setFormSubmited,
    hasNotGradableImage,
    forceToSendNotGradable,
    setIsLoading,
    files,
    referenceId,
    department,
    isPatientDiabetic,
    isNoContradiction,
    isEyeDropUsed,
    props.history,
    t,
  ]);

  const onDicomImageSelection = useCallback(
    (imgId, patientId, patientName) => {
      const key = dicomViewerOpenedForKey;
      setDicomViewerOpenedForKey(null);
      setFormSubmited(false);

      setReferenceId(patientId);

      setIsDicomFileBeingProcessed(true);
      DicomService.getImage(imgId)
        .then((blob) => {
          return ExamImagesApiService.checkImage(blob)
            .catch((e) => ({
              gradable: false,
              applicable: false,
            }))
            .then((checkResult) => {
              setFiles({
                ...files,
                [key]: { file: blob, checkResult, source: "dicom" },
              });
            });
        })
        .catch((e) => {
          message.error(t("upload.dicom-image-load-fail"));
        })
        .finally(() => {
          setIsDicomFileBeingProcessed(false);
        });
    },
    [
      dicomViewerOpenedForKey,
      setDicomViewerOpenedForKey,
      setFormSubmited,
      setReferenceId,
      files,
      setFiles,
      setIsDicomFileBeingProcessed,
      t,
    ]
  );

  const imageLabelMap = useMemo(
    () => ({
      "od-m": t("upload.imageLeftEyeMacula"),
      "od-d": t("upload.imageLeftEyeDisc"),
      "os-m": t("upload.imageRightEyeMacula"),
      "os-d": t("upload.imageRightEyeDisc"),
    }),
    [t]
  );

  const uploadRenderer = useCallback(
    (key) => {
      const stateFile = files[key];
      return (
        <>
          <Button
            className="eye-upload__dicom-search-btn"
            type="button"
            onClick={() => {
              setSelectedImage(null);
              setDicomViewerOpenedForKey(key);
            }}
            disabled={stateFile != null}
          >
            {t("upload-page.form.dicom-search-btn.label")}
          </Button>
          {(!stateFile || stateFile.source !== "dicom") && (
            <ImageUpload
              onPreviewLoad={setImageToViewer}
              onFileRemoved={() => onFileRemoved(key)}
              onFileUpload={(file, checkResult) =>
                onFileUpload(file, checkResult, key)
              }
              onFileUploadError={(file) => onFileUploadError(file, key)}
              overlayKey={key}
              label={imageLabelMap[key]}
            />
          )}
          {stateFile && stateFile.source === "dicom" && (
            <div>
              <FileImagePreview
                className={
                  "eye-upload__dicom-preview eye-upload__dicom-preview_" + key
                }
                file={stateFile ? stateFile.file : null}
                onPreviewLoad={setImageToViewer}
              >
                <Button
                  className="eye-upload__dicom-preview__delete-button"
                  type="link"
                  onClick={() => onFileRemoved(key)}
                >
                  <Icon
                    className="eye-upload__dicom-preview__delete-button-icon"
                    type="delete"
                  />
                </Button>
              </FileImagePreview>
              {stateFile.checkResult && (
                <ImageCheckStatus checkResult={stateFile.checkResult} />
              )}
            </div>
          )}
        </>
      );
    },
    [
      files,
      t,
      setSelectedImage,
      setDicomViewerOpenedForKey,
      setImageToViewer,
      onFileRemoved,
      onFileUpload,
      onFileUploadError,
      imageLabelMap,
      onFileRemoved,
    ]
  );

  return (
    <Layout className="upload-page">
      <div className="form upload-form">
        <h1>{t("common.upload")}</h1>
        <div className="image-uploads">
          <div>
            <h2>{t("common.OD")}</h2>
            <div className="eye-upload">
              {uploadRenderer("od-m")}
              {uploadRenderer("od-d")}
            </div>
          </div>
          <div>
            <h2>{t("common.OS")}</h2>
            <div className="eye-upload">
              {uploadRenderer("os-m")}
              {uploadRenderer("os-d")}
            </div>
          </div>
        </div>

        {userDepartments.length > 0 && (
          <div className="form-block">
            <label>{t("common.department")}</label>
            <Select value={department} onChange={onSelect}>
              {userDepartments.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d.name}
                </Option>
              ))}
            </Select>
          </div>
        )}
        <div className="form-block">
          <label>{t("upload.reference")}</label>
          <Input value={referenceId} onChange={onReferenceChange} />
        </div>
        <div className="form-block">
          <Checkbox onChange={(e) => setIsPatientDiabetic(e.target.checked)}>
            {t("viewer.diabetic")}
          </Checkbox>
          <Checkbox onChange={(e) => setIsNoContradiction(e.target.checked)}>
            {t("viewer.contraindication")}
          </Checkbox>
          <Checkbox onChange={(e) => setIsEyeDropUsed(e.target.checked)}>
            {t("viewer.eyeDrops")}
          </Checkbox>
        </div>

        {hasNotGradableImage && formSubmited && (
          <div className="form-block">
            <Checkbox
              onChange={(e) => setForceToSendNotGradable(e.target.checked)}
            >
              {t("upload.imagesGradibilityWarning")}
            </Checkbox>
          </div>
        )}

        <Button
          type="danger"
          disabled={isLoading || hasNoImages || hasInvalidImage || !referenceId}
          loading={isLoading}
          onClick={onSubmit}
        >
          {t("common.create")}
        </Button>
      </div>
      <div className="upload-page__viewer-area viewer">
        {selectedImage &&
          selectedImage.domImage &&
          dicomViewerOpenedForKey == null && (
            <ImageViewer
              hideMagnifier={true}
              image={selectedImage}
              className=" viewer__image-viewer"
            />
          )}
        {dicomViewerOpenedForKey != null && (
          <DicomViewer
            className="viewer__dicom-viewer"
            onImageSelection={onDicomImageSelection}
          />
        )}
        {isDicomFileBeingProcessed && (
          <Icon className="viewer__dicom-processing-icon" type="loading" />
        )}
      </div>
    </Layout>
  );
}

export default withRouter(AddExamPage);
