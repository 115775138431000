import axios from "axios";

export const OrganizationsService = {
    baseUrl: "/api/organizations",
	getAll() {
		return axios.get(this.baseUrl,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => {
			if (x.data) {
				return x.data.sort((a,b) => {
					return a.id - b.id;
				});
			}

			return [];
		});
	},
	getById(id) {
		return axios.get(`${this.baseUrl}/${id}`,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => x.data);
	},
	getSystemParamsForOrganization() {
		return axios.get(`${this.baseUrl}/getSystemParamsForOrganization`,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => x.data);
	},
	save(data) {
		return axios.post(`${this.baseUrl}/save`,
			data,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => x.data);
	},
	getLocales() {
		return axios.get(`${this.baseUrl}/locales`,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true },
		).then(x => {
			if (x.data) {
				return x.data;
			}

			return [];
		});
	}
}