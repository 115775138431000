import React from "react";
import SimpleButton from "../SimpleButton/SimpleButton";
import VesselsIcon from "../../../../../res/whiteVeins.svg"
import ROIIcon from "../../../../../res/roi.svg"
import "./MLButtonsSubpanel.css";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";

export function MLButtonsSubpanel(props) {
    const { t } = useTranslation();
    const handleROIMaskButton = () => {
        const { isROIShown, showMask } = props;
        showMask("isROIShown", !isROIShown)
    };

    const handleHEMaskButton = () => {
        const { isHEShown, showMask } = props;
        showMask("isHEShown", !isHEShown)
    };

    const handleMAMaskButton = () => {
        const { isMAShown, showMask } = props;
        showMask("isMAShown", !isMAShown)
    };

    const handleMXMaskButton = () => {
        const { isMXShown, showMask } = props;
        showMask("isMXShown", !isMXShown)
    };

    const handleVesselsMaskButton = () => {
        const { isVesselsMaskShown, showMask } = props;
        showMask("isVesselsMaskShown", !isVesselsMaskShown)
    };

    const renderMLButtons = (isDeseasePredicted) => {
        const {
            analysis, isROIShown, isHEShown, isMAShown, isMXShown
        } = props;

        if (isDeseasePredicted) {
            return (
                <>
                    {renderMLButton(analysis.ROI_contours, isROIShown, ROIIcon, "roi-button-pressed", t('viewer.ROI'), handleROIMaskButton)}
                    {renderMLButton(analysis.HE_contours, isHEShown, ROIIcon, "he-button-pressed", t('viewer.hemorrhages'), handleHEMaskButton)}
                    {renderMLButton(analysis.MA_contours, isMAShown, ROIIcon, "ma-button-pressed", t('viewer.microaneurysms'), handleMAMaskButton)}
                    {renderMLButton(analysis.MX_contours, isMXShown, ROIIcon, "mx-button-pressed", t('viewer.mixedExudates'), handleMXMaskButton)}
                </>
            )
        }
    };

    const renderVesselsButton = () => {
        const { vessels_mask, isVesselsMaskShown } = props;

        return renderMLButton(vessels_mask, isVesselsMaskShown, VesselsIcon, "vessels-button-pressed", t('viewer.vessels'),
            handleVesselsMaskButton);
    };

    const renderMLButton = (data, isButtonPressed, icon, pressedClassName, text, onClickHandler) => {
        if (data && (data.size > 0 || data.length > 0)) {
            return (
                <SimpleButton text={text}
                    icon={icon}
                    isButtonPressed={isButtonPressed}
                    pressedClassName={pressedClassName}
                    onClickHandler={onClickHandler} />
            )
        } else {
            return null;
        }

    };

    return (props.analysis && props.analysis.gradable) ?
        <div className="ml-buttons">
            {renderVesselsButton()}
            {renderMLButtons(props.analysis.desease_prediction)}
        </div> :
        null
}
