import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import { Table, Icon, Button, Input, Descriptions } from "antd";
import Highlighter from "react-highlight-words";
import "antd/dist/antd.css";
import "./DicomViewer.scss";
import { DicomService } from "../../api";
import { useTranslation } from "react-i18next";

const seriesDateFormatter = new Intl.DateTimeFormat("ru-RU", {
  dateStyle: "short",
});

const seriesPropsRenderFnsMap = {
  seriesDate: (text) =>
    text ? seriesDateFormatter.format(new Date(text)) : "-",
};

const SeriesRowRenderer = (props) => {
  const [imagesData, setImagesData] = useState(null);
  const [isImagesDataLoading, setIsImagesDataLoading] = useState(false);
  const { t } = useTranslation();
  const columnsImages = useMemo(
    () => [
      {
        title: t(
          "dicom-viewer.table.series-row-expanded.table.columns.image-name.label"
        ),
        dataIndex: "instanceNumber",
        key: "instanceNumber",
      },
      {
        title: t(
          "dicom-viewer.table.series-row-expanded.table.columns.image-id.label"
        ),
        dataIndex: "sopInstanceUID",
        key: "sopInstanceUID",
      },
      {
        title: "",
        key: "operation",
        render: (data) => (
          <Button
            type={"button"}
            onClick={
              props.onImageSelection
                ? () => {
                    props.onImageSelection(data.sopInstanceUID);
                  }
                : null
            }
          >
            {t(
              "dicom-viewer.table.series-row-expanded.table.select-image-btn.label"
            )}
          </Button>
        ),
      },
    ],
    [props.onImageSelection]
  );

  const translationsMap = useMemo(
    () => ({
      seriesDate: t("dicom-viewer.table.series-row-expanded.series-date"),
      seriesNumber: t("dicom-viewer.table.series-row-expanded.series-number"),
      seriesDescription: t(
        "dicom-viewer.table.series-row-expanded.series-description"
      ),
      modality: t("dicom-viewer.table.series-row-expanded.modality"),
      seriesInstanceUID: t(
        "dicom-viewer.table.series-row-expanded.series-instance-id"
      ),
      numberOfSlices: t(
        "dicom-viewer.table.series-row-expanded.number-of-slices"
      ),
    }),
    [t]
  );

  useEffect(() => {
    if (!props.seriesRecord.seriesInstanceUID) {
      setImagesData(null);
      return;
    }

    setIsImagesDataLoading(true);
    DicomService.getImages(props.seriesRecord.seriesInstanceUID)
      .then((series) => {
        setImagesData(series);
      })
      .catch((e) => {})
      .finally(() => {
        setIsImagesDataLoading(false);
      });
  }, [props.seriesRecord, setImagesData, setIsImagesDataLoading]);

  return (
    <>
      <Descriptions>
        {Object.entries(props.seriesRecord).map(([key, value]) => (
          <Descriptions.Item label={translationsMap[key]}>
            {value != null
              ? seriesPropsRenderFnsMap[key]
                ? seriesPropsRenderFnsMap[key](value)
                : value
              : "-"}
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Table
        className={"dicom-viewer-study-row__series-row dicom-viewer-series-row"}
        dataSource={imagesData}
        loading={isImagesDataLoading}
        columns={columnsImages}
        pagination={false}
        rowKey="id"
      />
    </>
  );
};

const studyPropsRenderFnsMap = {
  studyDate: (text) =>
    text ? seriesDateFormatter.format(new Date(text)) : "-",
};

const StudyRowRenderer = (props) => {
  const [data, setData] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { t } = useTranslation();

  const columnsSeries = useMemo(
    () => [
      {
        title: t(
          "dicom-viewer.table.study-row-expanded.table.columns.series-number"
        ),
        key: "seriesNumber",
        dataIndex: "seriesNumber",
      },
      {
        title: t(
          "dicom-viewer.table.study-row-expanded.table.columns.series-date.header"
        ),
        key: "seriesDate",
        dataIndex: "seriesDate",
        render: (text) =>
          text ? seriesDateFormatter.format(new Date(text)) : "-",
      },
      {
        title: t(
          "dicom-viewer.table.study-row-expanded.table.columns.series-description.header"
        ),
        key: "seriesDescription",
        dataIndex: "seriesDescription",
      },
      {
        title: t(
          "dicom-viewer.table.study-row-expanded.table.columns.modality.header"
        ),
        key: "modality",
        dataIndex: "modality",
      },
    ],
    [t]
  );

  useEffect(() => {
    if (!props.studyRecord.studyInstanceUID) {
      setData(null);
      return;
    }

    setIsDataLoading(true);
    DicomService.getSeries(props.studyRecord.studyInstanceUID)
      .then((series) => {
        setData(series);
      })
      .catch((e) => {})
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [props.studyRecord, setData, setIsDataLoading]);

  const translationsMap = useMemo(
    () => ({
      patientId: t("dicom-viewer.table.study-row-expanded.patient-id"),
      patientName: t("dicom-viewer.table.study-row-expanded.patient-name"),
      patientBirthDate: t(
        "dicom-viewer.table.study-row-expanded.patient-birth-date"
      ),
      studyInstanceUID: t(
        "dicom-viewer.table.study-row-expanded.study-instance-id"
      ),
      studyDate: t("dicom-viewer.table.study-row-expanded.study-date"),
      studyDescription: t(
        "dicom-viewer.table.study-row-expanded.study-description"
      ),
      numberOfStudyRelatedSeries: t(
        "dicom-viewer.table.study-row-expanded.number-of-study-related-series"
      ),
      numberOfStudyRelatedInstances: t(
        "dicom-viewer.table.study-row-expanded.number-of-study-related-instances"
      ),
    }),
    [t]
  );

  return (
    <>
      <Descriptions>
        {Object.entries(props.studyRecord).map(([key, value]) => (
          <Descriptions.Item label={translationsMap[key]}>
            {value != null
              ? studyPropsRenderFnsMap[key]
                ? studyPropsRenderFnsMap[key](value)
                : value
              : "-"}
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Table
        className={props.className + " dicom-viewer-study-row"}
        dataSource={data}
        columns={columnsSeries}
        loading={isDataLoading}
        rowKey="seriesInstanceUID"
        expandedRowRender={(seriesRecord) => (
          <SeriesRowRenderer
            seriesRecord={seriesRecord}
            onImageSelection={props.onImageSelection}
          />
        )}
        expandRowByClick
        pagination={false}
      />
    </>
  );
};

const patientBirthDateFormatter = new Intl.DateTimeFormat("ru-RU", {
  dateStyle: "short",
});
const studyDateFormatter = new Intl.DateTimeFormat("ru-RU", {
  dateStyle: "short",
});

const searchableColumns = ["patientId", "patientName", "studyDescription"];

export function DicomViewer(props) {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const searchInput = useRef(null);

  const columnsStudies = useMemo(
    () => [
      {
        title: t("dicom-viewer.table.columns.patient-id.header"),
        dataIndex: "patientId",
        key: "patientId",
      },
      {
        title: t("dicom-viewer.table.columns.patient-name.header"),
        dataIndex: "patientName",
        key: "patientName",
      },
      {
        title: t("dicom-viewer.table.columns.patient-birth-date.header"),
        dataIndex: "patientBirthDate",
        key: "patientBirthDate",
        render: (text) =>
          text ? patientBirthDateFormatter.format(new Date(text)) : "-",
      },
      {
        title: t("dicom-viewer.table.columns.study-date.header"),
        dataIndex: "studyDate",
        key: "studyDate",
        render: (text) =>
          text ? studyDateFormatter.format(new Date(text)) : "-",
      },
      {
        title: t("dicom-viewer.table.columns.study-description.header"),
        dataIndex: "studyDescription",
        key: "studyDescription",
      },
      {
        title: t(
          "dicom-viewer.table.columns.study-related-series-count.header"
        ),
        dataIndex: "numberOfStudyRelatedSeries",
        key: "numberOfStudyRelatedSeries",
      },
    ],
    [t]
  );

  useEffect(() => {
    if (data) {
      return;
    }

    setIsDataLoading(true);
    DicomService.getStudies()
      .then((studies) => {
        setData(studies);
      })
      .catch((e) => {})
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [data, setIsDataLoading]);

  const handleSearch = useCallback(
    (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchParams({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    },
    [setSearchParams]
  );

  const handleReset = useCallback(
    (clearFilters) => {
      clearFilters();
      setSearchParams({ searchText: "" });
    },
    [setSearchParams]
  );

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={t(
              "dicom-viewer.table.columns.search-box.search-input.placeholder"
            )}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t("dicom-viewer.table.columns.search-box.search-btn.label")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("dicom-viewer.table.columns.search-box.reset-btn.label")}
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#1890ff" : undefined }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchParams.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchParams.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    }),
    [searchParams, handleSearch, handleReset, t]
  );

  const columns = columnsStudies.map((colDef) =>
    searchableColumns.includes(colDef.key)
      ? {
          ...colDef,
          ...getColumnSearchProps(colDef.key),
        }
      : colDef
  );

  const onImageSelection = useCallback(
    (imgId, studyRecord) => {
      if (!props.onImageSelection) {
        return;
      }
      props.onImageSelection(
        imgId,
        studyRecord.patientId,
        studyRecord.patientName
      );
    },
    [props.onImageSelection]
  );

  return (
    <Table
      className={"dicom-viewer " + props.className || ""}
      loading={isDataLoading}
      dataSource={data}
      columns={columns}
      rowKey="studyInstanceUID"
      expandedRowRender={(studyRecord) => (
        <StudyRowRenderer
          className={"dicom-viewer__study-row"}
          studyRecord={studyRecord}
          onImageSelection={(imgId) => onImageSelection(imgId, studyRecord)}
        />
      )}
      expandRowByClick
      pagination={false}
    />
  );
}
