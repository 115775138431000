import React from "react";
import { Icon } from "antd";
import "./ImageCheckStatus.scss";
import { useTranslation } from "react-i18next";

export const ImageCheckStatus = ({ checkResult: { applicable, gradable } }) => {
  const { t } = useTranslation();

  return (
    <div className="app-image-check-status">
      {applicable ? (
        <div className="success">
          <Icon type="check" />{" "}
          {t("widgets.image-check-status.applicable-icon.label")}
        </div>
      ) : (
        <div className="error">
          <Icon type="close" />{" "}
          {t("widgets.image-check-status.not-applicable-icon.label")}
        </div>
      )}
      {gradable ? (
        <div className="success">
          <Icon type="check" />{" "}
          {t("widgets.image-check-status.gradable-icon.label")}
        </div>
      ) : (
        <div className="error">
          <Icon type="close" />{" "}
          {t("widgets.image-check-status.not-gradable-icon.label")}
        </div>
      )}
    </div>
  );
};
