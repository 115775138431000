import axios from "axios";
import { store } from "../store/configureStore";


export const ExamImagesApiService = {
	getImages(examinagionId) {
		const state = store.getState();
		return axios.get(`/api/examinations/${examinagionId}/images`,
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, withCredentials: true })
			.then(x => x.data)
	},
	getImageUrl(imageId) {
		return "/api/examinations/images/" + imageId
	},
	getVesselsMasksUrl(examImageMlResultId) {
		return `/api/examinations/analysis-result/${examImageMlResultId}/vessels-mask`;
	},
	getVesselsMasks(examImageMlResultId) {
		const state = store.getState();

		return axios.get(this.getVesselsMasksUrl(examImageMlResultId),
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, 
			responseType: 'blob',
			withCredentials: true })
			.then(x => {
				return x.data;
			})
	},
	getImage(imageId) {
		const state = store.getState();

		return axios.get(this.getImageUrl(imageId),
			{ headers: { "X-Requested-With": "XMLHttpRequest", organizationId: state.account.organization }, 
			responseType: 'blob',
			withCredentials: true })
			.then(x => {
				return x.data;
			})
	},
	checkImage(data) {
		const formData = new FormData();
			formData.append('image', data, `${Date.now()}.jpg`);

		return axios.post(`/api/checkImage`,
			formData,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true })
			.then(x => x.data)
	},
	getImagesZip(imageId) {
		return axios.get(`/api/examinations/${imageId}/imagesZip`,
			{ headers: { "X-Requested-With": "XMLHttpRequest" }, withCredentials: true })
			.then(x => x.data)
	}
}