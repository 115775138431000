import React, { Component, createRef, forwardRef } from "react";
import { Layout } from "antd";
import { Layer, Stage } from "react-konva";
import ContainerDimensions from "react-container-dimensions";
import VesselsMaskImage from "./VesselsMaskImage";
import ROIMaskImage from "./ROIMaskImage";
import { getCenterPosition, getPositionTo, getPositionToMouseCursor } from "../../../../utils/CanvasUtils";
import { Magnifier } from "./Magnifier";
import { FilterImage } from "./FilterImage";
import "./ImageViewer.css";
import "antd/dist/antd.css";


const { Content } = Layout;

class ImageViewer extends Component {
	delta;
	scrolling = true;

	state = {
		magnifierPosition: { x: 0, y: 0 },
		dragStart: false,
		cursorPosition: {
			x: 0,
			y: 0,
		},
		stageX: 0,
		stageY: 0,
		stageScale: 1,
		scalingSize: 1.085,
	};

	stageRef = createRef();
	magnifierRef = createRef();

	componentDidMount() {
		const magnifierPosition = JSON.parse(localStorage.getItem('magnifierPosition'))
		if (magnifierPosition)
			this.setState({ magnifierPosition });

		this.interval = setInterval(() => {
			this.scrolling = true;
		}, 120);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	handleMouseEnter = () => {
		document.body.style.cursor = "grab";
	};

	handleMouseDown = () => {
		document.body.style.cursor = "grabbing";
	};

	handleMouseLeave = () => {
		document.body.style.cursor = "default";
	};

	handleWheel = (event, maxWidth, maxHeight) => {
		if (this.scrolling === false) {
			return;
		}
		this.scrolling = false;

		const stage = event.target.getStage();
		event.evt.preventDefault();

		const stagePosition = {
			x: stage.x(),
			y: stage.y(),
		};

		if (event.evt.deltaY < 0) {
			const mousePointerPosition = {
				x: stage.getPointerPosition().x,
				y: stage.getPointerPosition().y,
			};
			this.zoomIn(mousePointerPosition, stagePosition);
		} else {
			const maxDimension = {
				width: maxWidth,
				height: maxHeight,
			};
			this.zoomOut(maxDimension, this.getImageOriginalDimension(), stage);
		}
	};

	zoomIn = (mousePointerPosition, stagePosition) => {
		const { stageScale, scalingSize } = this.state;
		const newScale = stageScale * scalingSize;

		const scalePoint = getPositionToMouseCursor(mousePointerPosition, stagePosition, stageScale, newScale);

		this.setState({
			stageX: scalePoint.x,
			stageY: scalePoint.y,
			stageScale: newScale,
		});
	};

	zoomOut = (maxDimension, componentOriginalDimension, stage) => {
		const { stageScale, scalingSize } = this.state;
		if (stageScale >= scalingSize) {
			const newScale = stageScale / scalingSize;
			const scalePoint = getCenterPosition(maxDimension, componentOriginalDimension, stage, stageScale, newScale);

			this.setState({
				stageX: scalePoint.x,
				stageY: scalePoint.y,
				stageScale: newScale,
			});
		}
	};

	getCenterCoords = (maxWidth, maxHeight, scaleDelta) => {
		const loadedImage = this.props.image.domImage;

		if (!loadedImage) return { x: 0, y: 0 }

		return {
			x: (maxWidth - loadedImage.width * scaleDelta) / 2,
			y: (maxHeight - loadedImage.height * scaleDelta) / 2,
		};
	};

	setStageDragBounds = (dragPosition, maxWidth, maxHeight) => {
		const { stageScale, stageX, stageY } = this.state;
		const loadedImage = this.props.image.domImage;
		const delta = this.getScaleDelta(maxWidth, maxHeight);
		return {
			x: getPositionTo(dragPosition.x, stageX, loadedImage.width * delta, maxWidth, stageScale),
			y: getPositionTo(dragPosition.y, stageY, loadedImage.height * delta, maxHeight, stageScale),
		};
	};

	getImageOriginalDimension = () => {
		const loadedImage = this.props.image.domImage;
		const dimension = {
			width: 0,
			height: 0,
		};

		if (loadedImage) {
			dimension.width = loadedImage.width;
			dimension.height = loadedImage.height;
		}

		return dimension;
	};


	getScaleDelta = (maxWidth, maxHeight) => {
		const loadedImage = this.props.image.domImage;

		if (loadedImage) {
			this.delta = maxWidth / loadedImage.naturalWidth;
			this.delta = (loadedImage.naturalHeight * this.delta < maxHeight) ?
				this.delta :
				maxHeight / loadedImage.naturalHeight;

			return this.delta;
		}
	};

	move = (e) => {
		this.setState({
			cursorPosition: {
				x: -e.evt.layerX,
				y: -e.evt.layerY,
			}
		})

	}

	timeout = undefined;
	updatePreview = () => {
		clearTimeout(this.timeout);

		this.timeout = setTimeout(
			() => {
				let p = this.stage.position();

				this.setState({
					stageX: p.x,
					stageY: p.y
				})
			},
			100
		);

	}

	startMagnifierDragging = (e) => {

		var clickInsideMagnifier = e.target.getBoundingClientRect();
		var magnifierOffset = {
			right: e.clientX - clickInsideMagnifier.right,
			bottom: e.clientY - clickInsideMagnifier.bottom
		}

		this.moveMagnifier = e => {
			this.setState({
				magnifierPosition: {
					x: document.body.clientWidth - e.x + magnifierOffset.right,
					y: document.body.clientHeight - e.y + magnifierOffset.bottom
				}
			});

			e.stopPropagation();
			return false;
		}

		this.onMagnifierMove = document.addEventListener('mousemove', this.moveMagnifier);
		this.onMagnifierUp = document.addEventListener('mouseup', e => {
			document.removeEventListener('mousemove', this.moveMagnifier);
			document.removeEventListener('mouseup', this.onMagnifierUp);

			localStorage.setItem('magnifierPosition', JSON.stringify(this.state.magnifierPosition));
		})
	}

	render() {
		const { stageScale } = this.state;
		const loadedImage = this.props.image.domImage;
		return (
			!this.props.image.loadFailed &&
			<Content id="imageViewer" className={this.props.className}>
				<ContainerDimensions>
					{({ width, height }) => {
						const scaleDelta = this.getScaleDelta(width, height)

						return (
							<>
								<Stage
									ref={stageEl => {
										this.stage = stageEl;
										this.stageRef.current = stageEl;
										if(!this.props.innerRef) {
											return;
										}
										const newCurrent = { stage: this.stageRef.current, magnifier: this.magnifierRef.current };
										if(typeof this.props.innerRef === 'function') {
											this.props.innerRef(newCurrent)
											return;
										}
										if(typeof this.props.innerRef === 'object') {
											this.props.innerRef.current = newCurrent;
										}
									}}
									onDragMove={this.updatePreview}
									draggable={true}
									width={width}
									height={height}
									x={this.state.stageX}
									y={this.state.stageY}
									scaleX={stageScale}
									scaleY={stageScale}>

									<Layer
										ref={node => {
											this.originalLayer = node;
										}}
										onMouseMove={this.move}
										onMouseEnter={this.handleMouseEnter}
										onMouseLeave={this.handleMouseLeave}
										onMouseDown={this.handleMouseDown}
										onMouseUp={this.handleMouseEnter}
										onWheel={(event) => this.handleWheel(event, width, height)}>

										{<FilterImage
											image={loadedImage}
											scale={scaleDelta}
											colorFilter={this.props.colorMode}
										/>}

										<VesselsMaskImage
											scaleCoef={scaleDelta}
											isVesselsMaskShown={this.props.isVesselsMaskShown}
											sourceImage={loadedImage}
											vessels_mask={this.props.vessels_mask}
											delta={this.delta} />

										{
											this.props.analysis && <ROIMaskImage
												stageScale={stageScale}
												isROIShown={this.props.isROIShown}
												isHEShown={this.props.isHEShown}
												isMAShown={this.props.isMAShown}
												isMXShown={this.props.isMXShown}
												sourceImage={loadedImage}
												analysis={this.props.analysis}
												delta={this.delta} />
										}

									</Layer>
								</Stage>

								{
									!this.props.hideMagnifier && <div
										onMouseDown={this.startMagnifierDragging}
										onMouseUp={this.stopDragging}
										style={{ position: 'fixed', bottom: this.state.magnifierPosition.y, right: this.state.magnifierPosition.x, border: 'white solid 1px' }}>
										<Magnifier
											ref={magnifierEl => {
												this.magnifierRef.current = magnifierEl;
												if(!this.props.innerRef) {
													return;
												}
												const newCurrent = { stage: this.stageRef.current, magnifier: this.magnifierRef.current };
												if(typeof this.props.innerRef === 'function') {
													this.props.innerRef(newCurrent)
													return;
												}
												if(typeof this.props.innerRef === 'object') {
													this.props.innerRef.current = newCurrent;
												}
											}}
											sizeСoefficient={4}
											scale={4}
											width={width}
											height={height}
											image={this.props.image}
											cursorPosition={{ x: this.state.cursorPosition.x / stageScale, y: this.state.cursorPosition.y / stageScale }}
											stagePosition={{ x: this.state.stageX / stageScale, y: this.state.stageY / stageScale }}
										>
										</Magnifier>
									</div>
								}
							</>
						);
					}
					}
				</ContainerDimensions>
			</Content>
		)
	}
}

export default forwardRef((props, ref) => <ImageViewer innerRef={ref} {...props}/>)
