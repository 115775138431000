import React, { useEffect, useState } from "react";
import { Form, Layout, Input, Button, Select, notification, Checkbox } from 'antd';
import {
	useParams
} from "react-router-dom";

import { OrganizationsService } from "../../../services/organizationsService";
import { PresetsService } from "../../../services/presetsService";
import { useTranslation } from "react-i18next";

function EditOrganizationsPage(props) {
	let { id } = useParams();
	const { getFieldDecorator } = props.form;
	const [isLoading, setIsLoading] = useState(true);
	const [isSavingInProgress, setIsSavingInProgress] = useState(false);
	const [organization, setOrganization] = useState(null);
	const [presets, setPresets] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		Promise.all([OrganizationsService.getById(id), PresetsService.get()])
			.then(([organization, presets]) => {
				setOrganization(organization);
				setIsLoading(false);
				props.form.setFieldsValue({
					name: organization.name,
					description: organization.description,
					preset: organization.securityPresetId,
					locale: organization.locale,
					threshold: organization.threshold
				});

				setPresets(presets);
			});
	}, []);

	const selectPreset = (preset) => {
		var org = { ...organization };

		org.securityPresetId = preset;

		setOrganization(org);
	}

	const selectLocale = (locale) => {
		var org = { ...organization };

		org.locale = locale;

		setOrganization(org);
	}

	const extAnalyzeChange = (e) => {
		var org = { ...organization };
		org.isExtendedAnalyze = e.target.checked;

		setOrganization(org);
	}

	const editOrganization = (e) => {
		e.preventDefault();

		props.form.validateFields((errors, values) => {
			if (!errors && organization) {
				setIsSavingInProgress(true);

				OrganizationsService.save({
					id: organization.id,
					securityPresetId: values.preset, name: values.name,
					description: values.description, locale: values.locale,
					isExtendedAnalyze: organization.isExtendedAnalyze,
					threshold: values.threshold
				})
					.then(() => {
						notification['success']({ message: t('admin.organizationEditedMessage') });
					})
					.finally(() => {
						setIsSavingInProgress(false);
					})
					.catch(() => {
						notification['warning']({ message: t('admin.failedToEditOrganization') });
					});
			}
		});
	}

	return (
		<Layout>
			{isLoading ? <div>Loading...</div> : <div>
				<h1 className="mb-2">
					{t('admin.editOrganization')}
					<strong>
						{organization.name || ''}
					</strong>
				</h1>
				<Form className="create-edit-form" onSubmit={editOrganization}>
					<div>
						<Form.Item label={t('admin.organizationName')} >
							{getFieldDecorator('name', {
								rules: [{ required: true, message: t('admin.organizationNameInvalid') }],
							})(
								<Input disabled={props.isReadonly} />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('common.description')}>
							{getFieldDecorator('description', {
								rules: [{ required: true, message: t('admin.departmentDescriptionInvalid') }],
							})(
								<Input disabled={props.isReadonly} />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('admin.preset')}>
							{getFieldDecorator('preset', {
								rules: [{ required: true, message: t('admin.presetNotSelected') }],
							})(
								<Select
									disabled={props.isReadonly}
									placeholder={t('admin.preset')}
									onChange={(x) => selectPreset(x)}
									style={{ width: 500 }}
								>
									{presets.map(item => (
										<Select.Option key={item.id} value={item.id}>
											{item.name}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('admin.locale')}>
							{getFieldDecorator('locale', {
								rules: [{ required: true, message: t('admin.localeNotSelected') }],
							})(
								<Select
									placeholder={t('admin.locale')}
									onChange={(x) => selectLocale(x)}
									style={{ width: 500 }}
								>
									{organization.availableLocales.map(item => (
										<Select.Option key={item.code} value={item.code}>
											{item.description}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item label={t('admin.threshold')}>
							{getFieldDecorator('threshold', {
								rules: [{ required: true, message: t('admin.thresholdInvalid') }],
							})(
								<Input disabled={props.isReadonly} />,
							)}
						</Form.Item>
					</div>
					<div>
						<Form.Item>
							{getFieldDecorator('extendedAnalyze', {
								initialValue: organization.isExtendedAnalyze,
								valuePropName: 'checked'

							})(
								<Checkbox onChange={(x) => extAnalyzeChange(x)}
								>
									{t('admin.extendedAnalyze')}
								</Checkbox>,
							)}
						</Form.Item>
					</div>
					<div>
						<Button htmlType="submit" loading={isSavingInProgress}
							type="primary" disabled={props.isReadonly}>
							{t('common.save')}
						</Button>
					</div>
				</Form>
			</div>}
		</Layout>
	);
}

export default Form.create()(EditOrganizationsPage);