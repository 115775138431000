import React, { useEffect } from "react";
import { Layout, Menu, Icon } from "antd";
import "antd/dist/antd.css";
import {
	Switch,
	Redirect,
	Link,
	useRouteMatch,
	withRouter, useHistory
} from "react-router-dom";
import WorklistPage from "../../pages/worklist/WorklistPage";
import SGWorklistPage from "../../pages/worklist/sgmu/SGWorklistPage";
import "./DashboardPage.scss";
import ViewerPage from "../viewer/ViewerPage";
import SGViewerPage from "../viewer/sgmu/SGViewerPage";
import { AuthenticationService } from '../../services/authenticationService';
import { LogoutWidget } from '../../components/LogoutWidget/LogoutWidget';
import AppVersion from "../../components/AppVersion/AppVersion";
import UploadPage from "../upload/UploadPage";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import FeedbackPage from "../feedback/FeedbackPage";
import AdminDashboardPage from "../admin-dashboard/AdminDashboardPage";
import FeatureRoute from "../../components/GuardedRoute/FeatureRoute";
import GuardedMenuItem from "../../components/GuardedMenuItem/GuardedMenuItem";
import { useSelector, useDispatch } from 'react-redux';
import UserOrganizations from '../../components/UserOrganization/UserOrganization';
import { setUserCurrentOrganization, setUserFeatures, cleanUserSetup } from "../../actions/accountActions";
import { UserService } from "../../services/userService";
import GuardedRenderer from "../../components/GuardedRenderer/GuardedRenderer";
import { UserFeaturesService } from "../../services/userFeaturesEnum";
import { updateSelectedMenuItem } from "../../reducers/menuSlice";
import { reset } from "../../reducers/paginationSlice";
import { resetMenu } from "../../reducers/menuSlice";
import ManualRun from "../manual-run/ManualRun";
import { useTranslation } from "react-i18next";
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';

const SubMenu = Menu.SubMenu;

function DashboardPage(props) {
	let { path, url } = useRouteMatch();
	const org = useSelector((x) => x.account.organization);
	const userOrganizations = useSelector((x) => x.account.organizations);
	const features = useSelector((x) => x.account.features);
	const currentMenuItem = useSelector((x) => x.menu);
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	let history = useHistory();

	useEffect(
		() => {
			var menuItemKey = UserFeaturesService.getMenuItemKeyByPath(props.location.pathname);
			dispatch(updateSelectedMenuItem(menuItemKey));
			const currentOrganization = userOrganizations.filter(x => x.id === org)[0];

			if (currentOrganization && currentOrganization.locale && i18n.language !== currentOrganization.locale) {
				switchLanguage(currentOrganization.locale);
			}
		},
		[props.location]
	)

	const handleLogout = () => {
		dispatch(cleanUserSetup());
		dispatch(reset());
		dispatch(resetMenu());
		localStorage.removeItem('persist:root');
	};

	const selectOrganization = (organizationId) => {
		dispatch(setUserCurrentOrganization(organizationId));

		UserService.loadFeatures(organizationId).then((data) => {
			dispatch(setUserFeatures(data));
			var redirect = UserFeaturesService.getDefaultRedirectRoute(data, props.location.pathname);

			if (redirect) {
				history.push(redirect.route);
			}
		});
	}

	const switchLanguage = (language) => {
		i18n.changeLanguage(language);
	}

	const getDefaultRedicrectRoute = () => {
		var redirect = UserFeaturesService.getDefaultRedirectRoute(features);

		if (redirect)
			return redirect.route;

		return '';
	}

	const isLanguageSwitchAvailable = () => {
		if (!userOrganizations)
			return false;

		const currentOrganization = userOrganizations.filter(x => x.id === org)[0];

		if (!currentOrganization || !currentOrganization.locale)
			return false;

		if (Array.isArray(currentOrganization.locale)) {
			return true;
		}

		return false;
	}

	const getAvailableLocales = () => {
		if (!userOrganizations)
			return [];

		const currentOrganization = userOrganizations.filter(x => x.id === org)[0];

		if (!currentOrganization || !currentOrganization.availableLocales)
			return [];

		return currentOrganization.availableLocales;
	}

	return (
		<Layout>
			<GuardedRenderer requiredFeature="FEEDBACK_CREATE">
				<FeedbackForm></FeedbackForm>
			</GuardedRenderer>
			<div className="menu-container">
				<div className="menu-image-container">
				<Link className="logo" to="/">Ретина</Link>
				</div>
				<Menu selectedKeys={[currentMenuItem]} mode="horizontal" className="main-menu">
					<GuardedMenuItem key="sgexams" name={t('menu.exams')} url={`${url}/examsList`} requiredFeature="SGMU_GRID_FEATURE" />
					<GuardedMenuItem key="exams" name={t('menu.exams')} url={`${url}/exams`} requiredFeature="SPAIN_GRID_FEATURE" />
					<GuardedMenuItem key="upload" name={t('menu.upload')} url={`${url}/upload`} requiredFeature={["CREATE_EXAM"]} />
					<GuardedMenuItem key="feedback" name={t('menu.feedback')} url={`${url}/feedback`} requiredFeature="FEEDBACK_GET_LIST" />
					<GuardedMenuItem key="admin" name={t('menu.admin')} url={`${url}/admin`} requiredFeature={["ALLOW_VIEW_POLICY_CONFIG"]} />
					<GuardedMenuItem key="manual-run" name={t('menu.manualRun')} url={`${url}/manualRun`} requiredFeature="MANUAL_RUN" />

					{
						<div className="left-navigation">
							{
								(userOrganizations && isLanguageSwitchAvailable()) &&
								<LanguageSwitch languages={getAvailableLocales()}
									onLanguageSwitch={switchLanguage} ></LanguageSwitch>
							}
							{(org && userOrganizations) && <UserOrganizations selectedOrganization={org}
								onOrganizationSelect={selectOrganization} list={userOrganizations}></UserOrganizations>}
						</div>

					}


					<Menu.Item key="manual"
						className="manual">
						<a href={`/resources/manual/${i18n.language}`}
							rel="noopener noreferrer"
							target="_blank">
							<Icon type="question-circle" />
						</a>
					</Menu.Item>
					<SubMenu
						key="userwidget"
						title={
							<div>
								<Icon type="user" />
							</div>
						}
						className="user-widget">
						<Menu.Item key="logout">
							<LogoutWidget handleClick={() => AuthenticationService.doLogout().then(() => handleLogout())} />
						</Menu.Item>
					</SubMenu>
				</Menu>
			</div>
			<div className="root-container">
				<Switch>
					{getDefaultRedicrectRoute() &&
						<Redirect exact from={`${path}`} to={getDefaultRedicrectRoute()} />}
					<FeatureRoute path={`${path}/exams`} requiredFeature={["SPAIN_GRID_FEATURE"]}>
						<WorklistPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/examsList`} requiredFeature={["SGMU_GRID_FEATURE"]}>
						<SGWorklistPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/upload`} requiredFeature={["CREATE_EXAM"]}>
						<UploadPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/feedback`} requiredFeature={["FEEDBACK_GET_LIST"]}>
						<FeedbackPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/viewer/:id/:eyeSide?`} requiredFeature={["VIEW_EXAM"]}>
						<ViewerPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/sgviewer/:id/:eyeSide?`} requiredFeature={["VIEW_EXAM_SGMU"]}>
						<SGViewerPage />
					</FeatureRoute>
					<FeatureRoute path={`${path}/manualRun`} requiredFeature={["MANUAL_RUN"]}>
						<ManualRun />
					</FeatureRoute>
					<FeatureRoute requiredFeature={["ALLOW_VIEW_POLICY_CONFIG"]}
						path={`${path}/admin`}>
						<AdminDashboardPage />
					</FeatureRoute>
				</Switch>
			</div>
			<AppVersion />
		</Layout>
	)
}

export default withRouter(DashboardPage);
