import axios from "axios";
import { store } from "../store/configureStore";

export const DicomService = {
  getStudies() {
    const state = store.getState();

    return axios
      .get(`/api/dicom/studies`, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          organizationId: state.account.organization,
        },
        withCredentials: true,
      })
      .then((x) => {
        return x.data;
      });
  },
  getSeries(studyInstanceUID) {
    const state = store.getState();

    return axios
      .get(`/api/dicom/series/${studyInstanceUID}`, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          organizationId: state.account.organization,
        },
        withCredentials: true,
      })
      .then((x) => x.data);
  },
  getImages(seriesInstanceUID) {
    const state = store.getState();

    return axios
      .get(`/api/dicom/images/${seriesInstanceUID}`, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          organizationId: state.account.organization,
        },
        withCredentials: true,
      })
      .then((x) => x.data);
  },
  getImage(imageId) {
    const state = store.getState();

    return axios.get(`/api/dicom/image/${imageId}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        organizationId: state.account.organization
      },
      responseType: "blob",
      withCredentials: true,
    })
    .then((x) => {
      return x.data;
    });
  },
};
